.page-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f7fb;
}

.page-title-container h1,
.page-title-container p {
  text-align: center;
  color: #122853;
}
.page-title-container h1 {
  margin-top: 100px;
 
  font-size: 40px;
  font-weight: 600;
  width: 100%;

}
.page-title-container p {
  margin-top: 18px;
  margin-bottom: 100px;
  font-size: 16px;
  font-weight: 500;
  word-wrap: normal;
}
