.hospCard {
  max-width: 360px;
  width: 360px;
  padding: 10px;
  padding: 20px;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 7px;
}

.hostCont {
  position: relative;
  cursor: pointer;
}

.hospCard .hostCont .map {
  width: 100%;
  border-radius: 8px;
  height: 179px;
  border-radius: 8px;
  overflow: hidden !important;
}

.hostCont .map div {
  overflow: hidden;
}

.hostCont h1 {
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
  color: #122853;
}

.hostCont p {
  font-weight: 500;
  font-size: 14px;
  color: #585858;
  margin-top: 6px;
}

.hostCont h3 {
  font-weight: 600;
  font-size: 24px;
  color: #014dd5;
  margin-top: 14px;
}

.locHeart {
  position: absolute;
  z-index: 100;
  top: 16px;
  right: 16px;
  border: 1px solid #e2e8f0;
  background-color: white;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locHeart:hover {
  width: 35px;
  height: 35px;
  transition: 0.2 ease;
}

.loader-map {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #869099;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
