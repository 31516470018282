.user-container {
  max-width: 1200px;
  min-height: 40vh;
  width: 90%;
  margin: 50px auto;
}

.user-photo-name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.user-photo-name img {
  cursor: pointer;
}

.user-photo-name div:first-child {
  margin-right: 20px;
}

.user-photo-name div:first-child img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #f5f7fb;
}

.user-photo-name div:last-child h1 {
  font-weight: 500;
  font-size: 32px;
  color: #122853;
}

.user-photo-name div:last-child p {
  font-weight: 500;
  font-size: 18px;
  color: #585858;
}

.user-cards-container {
  display: flex;
  flex-wrap: wrap;
}

.user-liked-card {
  margin: 10px 10px;
}
