/* Terms  */
.TermsConditions {
    max-width: 1200px;
    width: 90%;
    margin: 50px auto;
  }
  
  .TermsConditions h1 {
    font-weight: 600;
    font-size: 24px;
    color: #122853;
  }
  .termSec {
    margin: 40px;
  }
  .termSec p {
    margin-top: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  
  .termSubSec {
    margin-top: 40px;
  }
  .contents {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .content {
    display: flex;
  }
  .content p {
    line-height: 28px;
  }
  .content img {
    width: 24px;
    height: 24px;
    margin-right: 18px;
  }
  .bottom {
    margin-top: 70px;
  }

  /* Chat.css */
.chat-input {
    width: 70%;
    height: 40px;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
  }
  
  .chat-button {
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .chat-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* ... previous CSS styles ... */

.chat-entry {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  
  .chat-entry.user {
    justify-content: flex-end;
  }
  
  .chat-entry.user .message-bubble {
    background-color: blue;
    color: white;
  }
  
  .chat-entry.bot .message-bubble {
    background-color: lightgray;
  }
  
  .message-bubble {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 15.5px;
    max-width: 80%;
  }
  
  .sender-name {
    color: #122853;
    margin-right: 10px;
  }
  
  .chat-suggestions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
     margin-top: 60px;  /* Updated from 10px to 40px */  }
  
  .chat-suggestion {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 10px 20px;
    background-color: lightgray;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .chat-suggestion:hover {
    background-color: gray;
    color: white;
  }

  .message-bubble.typing {
    animation: pulsate 1s infinite;
  }
  
  @keyframes pulsate {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }

  .bot-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .bot-icon-circle,
  .user-icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: bold;
  }
  
  .bot-icon-circle {
    background-color: lightgray;
    color: black;
  }
  
  .user-icon-circle {
    background-color: blue;
    color: white;
    margin-left: 10px;
  }
  
  
  
  @media only screen and (max-width: 600px) {

  .chat-suggestions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
     margin-top: 120px;  
    }

    .chat-suggestion {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      padding: 5px 9px;
      background-color: lightgray;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  
  }
