.articles {
  max-width: 1200px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 29px;
  row-gap: 40px;
  justify-content: center;
  margin: 50px auto;
}
