* {
  margin: 0;
  padding: 0;
  font-family: poppins;
  box-sizing: border-box;
}
body {
  font-family: poppins;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
ul,
ol,
div {
  list-style: none;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(233, 233, 233);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(56, 56, 56);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1b1b1b;
}
