.navbar {
  width: 100%;
  background-color: #f5f7fb;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  margin: 0px auto;
  padding: 30px 0px;
}
.logo {
  color: #122853;
  font-weight: 700;
  font-size: 1.4rem;
}
.logo span {
  background-color: #014dd5;
  color: #ffffff;
  padding: 0px 5px;
  border-radius: 5px;
  font-weight: 600;
  margin-right: 5px;
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: 40px;
}
.menu li a {
  margin: 0px 20px;
  color: #303030;
  font-weight: 500;
  transition: all ease 0.3s;
}
.menu li a:hover {
  color: #014dd5;
}
.nav-appointment-btn {
  margin-right: 20px;
  height: 45px;
  padding: 0px 20px;
  border-radius: 4px;
  background-color: #014dd5;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.9rem;
}
#loginBtn {
  display: none;
}

.menu li.cash a:hover {
  color: green;
}

.menu li.lisa a:hover {
  color: purple;
}

/* Avatar  */
.avatar {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: -150%;
  z-index: 1;
}

.dropbtn {
  background-color: transparent;
  color: #555;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.dropdown-content a {
  color: #555;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.avatar:hover .dropdown-content {
  display: block;
}

@media (max-width: 950px) {
  .navigation {
    justify-content: space-between;
    padding: 30px 0px;
    position: relative;
    width: 100%;
    padding: 20px;
  }
  .logo,
  .nav-appointment-btn {
    z-index: 103;
  }
  .logo {
    margin-right: auto;
    margin-left: 20px;
  }
  .menu-button {
    display: block;
  }
  #show {
    display: block;
  }
  .navigation .menu {
    display: none;
    position: absolute;
    left: 0px;
    top: 100%;
    background-color: #ffffff;
    width: 100%;
    padding: 0px;
    margin: 0px;
    z-index: 102;
    box-shadow: 2px 30px 30px rgba(0, 0, 0, 0.06);
  }
  .navigation .menu::after {
    content: "";
    position: absolute;
    left: 0px;
    top: -100%;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }
  .navigation .menu li {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .navigation .menu li a {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 30px 20px;
  }
  .menu-icon {
    display: block;
  }
  .navigation .menu-icon {
    cursor: pointer;
    float: right;
    padding: 10px 0px;
    position: relative;
    user-select: none;
    z-index: 106;
  }
  .navigation .menu-icon .nav-icon {
    background-color: #181818;
    display: block;
    position: relative;
    height: 2px;
    transition: background 0.2s ease-out;
    width: 25px;
  }
  .navigation .menu-icon .nav-icon::before,
  .navigation .menu-icon .nav-icon::after {
    background-color: #181818;
    content: "";
    position: absolute;
    height: 100%;
    transition: all ease-out 0.2s;
    width: 100%;
  }

  .navigation .menu-icon .nav-icon::before {
    top: 9px;
  }
  .navigation .menu-icon .nav-icon::after {
    top: -9px;
  }
  .navigation .menu-icon:checked ~ .menu-icon .nav-icon,
  .navigation .menu-btn:checked ~ .menu-icon .nav-icon {
    background-color: transparent;
  }
  .navigation .menu-btn:checked ~ .menu-icon .nav-icon::before {
    transform: rotate(-45deg);
    top: 0px;
  }
  .navigation .menu-btn:checked ~ .menu-icon .nav-icon::after {
    transform: rotate(45deg);
    top: 0px;
  }
}

@media (max-width: 450px) {
  #loginBtn {
    display: block;
  }
  .nav-appointment-btn {
    display: none;
  }
}
