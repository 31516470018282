.privacy {
    max-width: 1200px;
    width: 90%;
    margin: 50px auto;
  }
  .section {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  /* .section h1 {
    margin-bottom: 14px;
  } */
  
  h1 {
    font-weight: 600;
    font-size: 24px;
    color: #122853;
  }
  
  h2 {
    font-weight: 600;
    font-size: 18px;
    color: #122853;
  }
  
  p{
    font-size: 14px;
  }
