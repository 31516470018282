.billing-container {
  margin: 20px;
  padding: 60px 40px;
  background-color: #ffffff;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.billing-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 60px;
}

.billing-header-content {
  flex: 1;
}

.billing-title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.3;
}

.billing-section-title p {
  text-align: center;
  font-size: 2.6em; /* Adjust as necessary */
  font-weight: bold;
  color: #122853;
}

.billing-navy {
  color: #51cc55;
}




.billing-cash {
  color: #51cc55;
}

.billing-description {
  font-size: 20px;
  margin-bottom: 40px;
  line-height: 1.4;
}

.billing-button {
  background-color: #014dd5;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  display: inline-block;
}

.billing-button:hover {
  background-color: #4CAF50;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.billing-header-image {
  max-width: 400px;
  height: auto;
}

.billing-statistic {
  font-size: 18px;
  margin-bottom: 40px;
  line-height: 1.5;
  border-left: 4px solid #014dd5;
  padding-left: 20px;
  font-style: italic;
}

.billing-steps-section {
  margin: 20px 0;
}

.billing-step-wrapper {
  background-color: #F7F8FA;
  margin-bottom: 40px;
  padding: 40px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  width: 80%; /* or any other percentage or fixed value */
  max-width: 800px; /* or any other fixed value */
  margin-left: auto;
  margin-right: auto;
}


.billing-step-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.billing-step-content h3 {
  font-size: 28px;
  margin-bottom: 5px;
  margin-top: 21px;
}

.billing-step-content p {
  font-size: 18px;
  margin-bottom: 10px;
}

/* .billing-step-image img {
  max-width: 240px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
} */

.billing-step-image img {
  max-width: 240px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
}


.billing-images {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 20px 0;
}

.billing-left-image {
  flex-basis: 40%;
  max-width: 42%;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.billing-benefits-list {
  flex-basis: 55%;
  list-style-type: none;
  padding-left: 0;
}

.billing-benefit {
  font-size: 18px;
  margin-bottom: 44px;
  margin-left: 70px;
  color: #122853;
}

.billing-benefit strong {
  display: block;
  margin-bottom: 8px;
}

.billing-conclusion {
  font-size: 18px;
  margin: 40px 0;
  line-height: 1.6;
}

.ending {
  text-align: center;
}

.billing-benefits-list .billing-benefit {
  position: relative;
  padding-left: 40px; /* Create space for the icon */
}

.billing-benefits-list .billing-benefit span::before {
  content: url('/public/checkmark_resized2.png'); /* Add your circle check icon */
  position: absolute;
  left: 0;
  top: 10%;
  transform: translateY(-50%);
  width: 5px; /* Set width */
  height: 5px; /* Set height */
}

.billing-benefits-text {
  flex-basis: 55%;
}

.billing-benefits-heading {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 70px;
  color: #122853;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) { /* Usually targeting tablet sizes and below */
  .billing-container {
    padding: 20px;
  }

  .billing-header {
    flex-direction: column; /* Stack the elements */
  }

  .billing-header-content,
  .billing-header-image {
    text-align: center;
  }

  .billing-title {
    font-size: 32px;
  }

  .billing-benefits-heading {
    font-size: 34px;
    margin-bottom: 30px;
    text-align: left;
    margin-left: 30px;
  }

  .billing-header-image {
    width: 280px; /* Adjust the width for mobile screens */
    margin-top: 20px; /* Add space above the image */
  }

  .billing-button,
  .billing-button-bottom {
    font-size: 16px;
    padding: 12px 18px; /* Adjust the button padding */
  }

  .billing-steps-section,
  .billing-steps-container {
    flex-direction: column;
  }

  .billing-left-image {
    float: none; /* Don't float the image */
    margin: 0 auto 20px auto; /* Center the image and add margin below */
    display: block; /* Make sure it's displayed as a block */
    width: 250px; /* Adjust the width */
  }

  /* .billing-step-wrapper {
    padding: 5px;
  } */


  .billing-step-wrapper {
    background-color: #F7F8FA;
    margin-bottom: 40px;
    padding: 1px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    width: 80%; /* or any other percentage or fixed value */
  } 

  .billing-step,
  .billing-step-reverse {
    flex-direction: column;
  }

  .billing-step-content,
  .billing-step-image {
    flex-basis: 100%; /* Make them take full width on mobile */
    text-align: center; /* Center the text */
  }

  .billing-step-content h3 {
    font-size: 20px;
    padding-top: 20px; 
  }

  .billing-step-content p {
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .billing-images {
    display: flex;
    flex-direction: column;  /* Stack the image and list vertically */
    align-items: center;     /* Center the contents */
  }

  .billing-images img {
    width: 100%; /* or any other percentage or fixed value */
    max-width: 250px; /* or any other fixed value */
  }
  



  .billing-left-image {
    order: -1;  /* Force the image to appear first */
    margin-bottom: 20px;  /* Add some space between the image and the list */
  }

  .billing-benefits-list {
    width: 100%;
    padding: 0 10px 0 0px; /* top right bottom left */
    margin-left: -30px;
}

}

.billing-benefits-list .billing-benefit {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.billing-benefits-list.in-view .billing-benefit {
  opacity: 1;
  transform: translateY(0);
}

.billing-benefits-list.in-view .billing-benefit:nth-child(1) { transition-delay: 0.5s; }
.billing-benefits-list.in-view .billing-benefit:nth-child(2) { transition-delay: 0.7s; }
.billing-benefits-list.in-view .billing-benefit:nth-child(3) { transition-delay: 0.9s; }
.billing-benefits-list.in-view .billing-benefit:nth-child(4) { transition-delay: 1.1s; }
.billing-benefits-list.in-view .billing-benefit:nth-child(5) { transition-delay: 1.3s; }


