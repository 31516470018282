.doctCard {
  background: #ffffff;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 575px;
  padding: 20px;
  position: relative;
}

.img {
  width: 300px;
}

.img img {
  width: 100%;
  height: 150px;
}

.cardCont {
  display: flex;
  align-items: center;
}

.cardCont img {
  width: 100%;
  border-radius: 8px;
}

.dec {
  margin-left: 22px;
}

.dec h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 10px;
  color: #122853;
}

.dec h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #585858;
  margin-bottom: 10px;
}

.location {
  /* margin-bottom: 17px; */
  display: flex;
}

.dec p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #585858;
}
.website {
  line-height: 30px;
}

.readMore {
  color: blue;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .cardCont {
    flex-direction: column;
    text-align: center;
  }
  .location {
    justify-content: center;
  }
}
