.hosp-details-container {
  max-width: 1200px;
  width: 90%;
  margin: 50px auto;
  display: flex;
}

.hosp-det-img {
  width: 50%;
}

.hosp-det-img img {
  width: 100%;
}

.hosp-det-content {
  width: 50%;
  padding-left: 15px;
}

.hosp-det-content a {
  margin: 10px 0;
}

@media (max-width: 700px) {
  .hosp-details-container {
    flex-direction: column;
  }
  .hosp-det-img {
    width: 100%;
  }

  .hosp-det-content {
    width: 100%;
    padding-left: 0px;
  }
}
