.articleCard {
  max-width: 350px;
/* height: 639px; */
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
}

.articleCard h2,
.articleCard p {
  text-align: left;
}
.articleCard h2 {
  font-size: 28px;
}
.articleCard p {
  font-size: 14px;
}
.articleCardImg {
  width: 100%;
  height: 280px;
}
