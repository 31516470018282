.faq-container {
  max-width: 1200px;
  width: 80%;
  margin: auto;
  margin-bottom: 150px;
}

.faqs-history {
  margin: 40px 0;
}

.Faqs h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #122853;
}

.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accordion-item {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  margin-bottom: 10px;
  /* height: 72px; */
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.faq-question {
  line-height: 0;
  font-weight: 500;
  font-size: 16px;
  color: #122853;
}

.accordion-header span {
  transition: transform 0.3s ease-in-out;
}

.fa-plus,
.fa-minus {
  font-size: 32px;
}

.faq-answer {
  padding: 0 10px;
}
