.med-cen-container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.med-cen-backIcon {
  width: 100%;
  margin: 40px 0;
}

.med-cen-cardsCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.title h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #122853;
}

.title span {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #122853;
  margin-bottom: 35px;
}

.med-cen-cardsCont .map1 {
  width: 100%;
  height: 390px;
  /* background-color: black; */
  /* filter: drop-shadow(0px 3px 24px rgba(0, 0, 0, 0.1)); */
  border-radius: 20px;
  overflow: hidden;
}

.map-container-det {
  height: 100%;
  width: 100%;
}

.med-cen-center {
  margin: 70px 0;
}

.med-cen-center h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #122853;
  text-align: center;
}

.med-cen-center p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #122853;
}

.procedure-name{
  font-size: 20px;
}
/* .distance-away{
  margin-bottom: 20px;
} */

.med-cen-bottom {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 120px;
}

.med-cen-disc-price h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: justify;
  color: #014dd5;
}

.med-cen-disc-price p {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
  color: #585858;
}

.range-slider {
  position: relative;
  margin-left: 100px;
}
.slider {
  -webkit-appearance: none;
  width: 599px;
  height: 24px;
  margin-top: 60px;
  outline: none;
  /* -webkit-transition: 0.2s;
  transition: opacity 0.2s; */
  background-color: #e2e8f0;
  border-radius: 15px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 150px;
  height: 24px;
  background: blue;
  position: relative;
  border-radius: 15px;
}

/* .range-thumb {
  position: absolute;
  top: 40px;
  width: 150px;
  /* background-color: red; */
/* display: flex;
  justify-content: space-between; */
/* } */
/* */
.range-arrow {
  position: absolute;
  top: -50px;
  /* width: 150px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: blue;
}
.range-arrow span {
  position: absolute;
  top: -10px;
}
.range-arrow i {
  position: absolute;
  top: 5px;
}

.range-left-right-val {
  width: 599px;
  /* margin-top: 30px; */
  display: flex;
  justify-content: space-between;
}

@media (max-width: 950px) {
  .range-slider {
    margin-left: 0;
  }
}

@media (max-width: 750px) {
  .med-cen-bottom {
    flex-direction: column;
  }
  .slider {
    width: 400px;
  }
  .range-left-right-val {
    width: 400px;
  }
  .range-thumb {
    left: 50px;
  }
}
@media (max-width: 450px) {
  .slider {
    width: 300px;
  }
  .range-left-right-val {
    width: 300px;
  }
  .range-thumb {
    left: 30px;
  }
  @media (max-width: 400px) {
    .med-cen-img {
      width: 350px;
    }
  }
  @media (max-width: 370px) {
    .med-cen-img {
      width: 300px;
    }
  }
}
