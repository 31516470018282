/*==appointment-search=====================*/
.appointment-search-container {
  max-width: 1200px;
  width: 90%;
  background-color: #ffffff;
  padding: 20px;
  margin: 50px auto;
  border-radius: 10px;
  box-shadow: 2px 16px 30px rgba(0, 0, 0, 0.12);
  position: relative;
}
.appointment-search-container h3 {
  color: #122853;
  font-size: 1rem;
  margin-bottom: 5px;
}
.appointment-search {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 60px;
  grid-gap: 20px;
}
.appo-search-box {
  /* width: 400px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7fbff;
  padding: 15px 5px;
  margin: 0 5px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  position: relative;
}
.listItem {
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
}
.listItem:hover {
  background-color: #f5f5f5;
}

.appo-search-box .predicted-list {
  position: absolute;
  background-color: white;
  top: 100%;
  width: 100%;
  z-index: 10;
  /* padding: 20px; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 0px 0px rgba(0, 0, 0, 0);
}
.appo-search-box i {
  color: #535353;
  margin-right: 10px;
}
.appo-search-box input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}
.appointment-search button {
  background-color: #014dd5;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
}

.searchBtn:hover {
  background-color: #123a7f;
}

/*==making-responsive====================*/

@media (max-width: 620px) {
  .appointment-search {
    grid-template-columns: 1fr;
  }
  .appointment-search button {
    height: 45px;
  }
}
/* @media (max-width: 320px) {
  .nav-appointment-btn {
    display: none;
  }
} */
